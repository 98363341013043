import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const NavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false); // State to control burger menu

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu open/close state
  };

  return (
    <nav>
      
        <div className='logo-container'>
        <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>

      
        {/* Burger icon that toggles menu on mobile */}
        <div className='burger-icon-container'>
        <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
       
        {/* Menu links */}
        <div className={`menu-container ${isOpen ? 'open' : ''}`}>
          
          <ul>
            <li>
              <Link to="/#pricing">Pricing</Link>
            </li>

            <li>
              <Link to="/#contact">Share your vision</Link>
            </li>
          </ul>

        </div>
      
    </nav>
  );
}

export default NavBar;
