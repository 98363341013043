import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import caseStudiesData from "../case-studies/caseStudiesData";


import heart from "../../assets/images/work/heart.svg";

const CaseStudiesCards = ({ caseStudies, openCaseStudy }) => {
  return (
    <div className="case-studies-card-container">
      {caseStudies.map((study, index) => (
        <div
          key={index}
          className="case-studies-card animated"
          onClick={() => openCaseStudy(study.id)}
        >
          {study.cover && (
            <img
              src={study.cover}
              alt={study.title}
              className="case-studies-card_thumbnail"
              loading="lazy"
            />
          )}

          <h3 className="case-studies-card-title">{study.title}</h3>

          {/* Render Tags <div className="case-studies-card-tags">
            {study.tags &&
              study.tags.map((tag, tagIndex) => (
                <p
                  key={`${index}-${tagIndex}`}
                  className="case-studies-card-tag"
                >
                  {tag}
                </p>
              ))}
          </div>*/}

        </div>
      ))}
    </div>
  );
};

const CaseStudiesContainer = () => {
  const [selectedCategory, setSelectedCategory] = useState("Featured");
  const navigate = useNavigate();

  // Extract unique categories, including 'All'
  const categories = [
    "All",
    ...new Set(caseStudiesData.flatMap((study) => study.categories)),
  ];

  // Filter case studies based on selected category
  const filteredCaseStudies =
    selectedCategory === "All"
      ? caseStudiesData
      : caseStudiesData.filter((study) =>
          study.categories.includes(selectedCategory)
        );

  // Handle navigation to case study
  const handleOpenCaseStudy = (id) => {
    navigate(`/case-study/${id}`); // Navigate to case study route
  };

  return (
    <>
      
      <div className="tabs-main-container ">
        <img src={heart} alt="work crated with heart" loading="lazy"/>
        <h2 className="animated">Featured Work. Built with heart.</h2>

        {/* Filter Buttons */}
        <div className="case-studies-categories-container animated">
          {categories.map((category) => (
            <button
              key={category}
              className={`${selectedCategory === category ? "active" : ""}`}
              onClick={() => setSelectedCategory(category)}
            >
              <span>{category}</span>
            </button>
          ))}
        </div>

        {/* Render Case Studies */}
        <CaseStudiesCards
          caseStudies={filteredCaseStudies}
          openCaseStudy={handleOpenCaseStudy}
        />
      </div>
    </>
  );
};

export default CaseStudiesContainer;
