import React from "react";

import designImage from "../../assets/images/services/design.svg";
import uiImage from "../../assets/images/services/UI.svg";
import websiteImage from "../../assets/images/services/website.svg";
import brandingImage from "../../assets/images/services/branding.svg";
import appImage from "../../assets/images/services/app.svg";
import mvpImage from "../../assets/images/services/mvp.svg";

import eyeImage from "../../assets/images/services/eye.svg";



const Services = () => {
  return (
    <>
      <div className="services-container">
        <div className="services-title-container">
          <img className="animated" src={eyeImage} alt="your vision, fully executed" loading="lazy"/>
          <h2 className="animated">Your vision, fully executed.</h2>
          <p className="animated">
            Empowering Founders to create products that last and brands that captivate.
          </p>
        </div>

        <div className="services-item-container">
          <div className="service-item animated">
            <div>
              
              <h3>Custom Design</h3>
              <p>
              From simple pitch decks to complex animations.
              </p>
            </div>

            <img src={designImage} alt="" />

          </div>

          <div className="service-item animated">
            <div>
              
              <h3>Brand Design</h3>
              <p>
              Shape a brand that stands out, resonates deeply, and builds loyalty.
              </p>
            </div>

            <img src={brandingImage} alt="" />

          </div>

          <div className="service-item animated">
            <div>
              
              <h3>UI/UX Design</h3>
              <p>
              Irresistible UI's that make your users wanna lick the screen.
              </p>
            </div>

            <img src={uiImage} alt="" />

          </div>

          <div className="service-item animated">
            <div>
              
              <h3>Website Development</h3>
              <p>
              Inspire trust, lead with confidence, and connect with your audience.
              </p>
            </div>

            <img src={websiteImage} alt="" />

          </div>

          <div className="service-item animated">
            <div>
              
              <h3>MVP Development</h3>
              <p>
              Your envisioned MVP, brought to life in just 2 weeks.
              </p>
            </div>

            <img src={mvpImage} alt="" />

          </div>

          <div className="service-item animated">
            <div>
              
              <h3>App Development</h3>
              <p>
              Your dream product, coded with purpose and built to impact.

              </p>
            </div>

            <img src={appImage} alt="" />

          </div>

          {/*######### */}

        </div>
      </div>
    </>
  );
};

export default Services;
