import React from "react";

import orange from "../../assets/benefits/orange.svg";
import blue from "../../assets/benefits/blue.svg";
import green from "../../assets/benefits/green.svg";
import violet from "../../assets/benefits/violet.svg";



const Benefits = () => {


  return (
    <div className="benefits-container ">

      <div className="benefits-title" loading="lazy">
        <h2 className="animated">Building for Modern Founders & Startups</h2>
        <p>Relentless focus, fast execution, and a commitment to the quality of craft.</p>
      </div>

      {/* Attach the ref to this container */}
      <div className="grid-container_benefits" >
        <div className="benefit-item animated ">
          <img src={orange} alt="Strategic Art Direction" loading="lazy" />
          <div className="benefit-title_container" id="benefit-orange">
            <h3>Strategic Art Direction</h3>
            <p>Stand out with a brand that tells your unique story.</p>
          </div>
        </div>

        <div className="benefit-item animated">
          <img src={green} alt="Fast experience" loading="lazy" />
          <div className="benefit-title_container" id="benefit-green">
            <h3>Lightning Fast Experience</h3>
            <p>Quick, high-quality designs with seamless 2-day turnarounds.</p>
          </div>
        </div>

        <div className="benefit-item animated">
          <img src={blue} alt="Pixel perfect designs" loading="lazy" />
          <div className="benefit-title_container" id="benefit-blue">
            <h3>Crafted to Perfection</h3>
            <p>Every pixel, word, image and line of code built to perfection.</p>
          </div>
        </div>

        <div className="benefit-item animated">
          <img src={violet} alt="Pixel perfect designs" loading="lazy" />
          <div className="benefit-title_container" id="benefit-violet">
            <h3>Conversion-Focused</h3>
            <p>Designs that drive results—engagement, sells and user loyalty.</p>
          </div>
        </div>

     

      </div>
    </div>
  );
};

export default Benefits;
