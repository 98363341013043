import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useRef, useEffect, lazy, Suspense } from "react";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Hero from "./components/Hero/Hero";
import Reviews from "./components/Reviews/Reviews";
import Cta from "./components/Cta/Cta";
import CaseStudy from "./components/CaseStudy/CaseStudy";
import NavBar from "./components/NavBar/NavBar";

import Services from "./components/Services/Services";
import CaseStudiesContainer from "./components/CaseStudiesCards/CaseStudiesContainer";
import Benefits from "./components/Benefits/Benefits";
import FAQ from "./components/faq/faq";
import Pricing from "./components/Pricing/Pricing";
import Footer from "./components/Footer/Footer";


const callendlyLink = "https://calendly.com/roccostudio/30min";
const gmailLink =
  "https://mail.google.com/mail/u/0/?fs=1&to=rocofernandezpagella@gmail.com&su=Meeting%20for%20services&body=&bcc=rocofernandezpagella@icloud.com&tf=cm";


gsap.registerPlugin(ScrollTrigger);




function App() {

  
  
  // ANIMATION 1
  const isMobile = () => window.innerWidth <= 768; // Adjust the breakpoint if necessary

  useEffect(() => {
    // Check if it's mobile, return early if true
    if (isMobile()) return;
  
    const elements = document.querySelectorAll(".animated");
  
    elements.forEach((element) => {
      // Desktop animations only
      gsap.fromTo(
        element,
        { opacity: 0.1, y: 10, filter: "blur(5px)" },
        {
          y: 0,
          opacity: 1,
          filter: "blur(0px)",
          duration: 0.5,
          ease: "power4.out",
          scrollTrigger: {
            trigger: element,
            start: "top 90%",
            end: "bottom 80%",
            scrub: true,
          },
        }
      );
    });
  
    // Recalculate animation on window resize for desktop
    window.addEventListener("resize", () => {
      ScrollTrigger.refresh();
    });
  
  }, []);
 

  return (
    <>
      <Routes>
        <Route path="/" element={
         
            <>
              
              <Hero 
                title="World-Class Solutions for the Sartups of Tomorrow"
                subtitle="A Design and Development studio that helps Startup and SaaS Founders build the products of their dreams."
                callendlyLink={callendlyLink}
                gmailLink={gmailLink}
              />
              <Services 
                callendlyLink={callendlyLink}
                gmailLink={gmailLink}
              />
              <CaseStudiesContainer />
              <Benefits />
              <Reviews />
              <Pricing/>
              <Cta 
                callendlyLink={callendlyLink}
                gmailLink={gmailLink}
              />
              <FAQ />
              <Footer />
            </>
          
        } />
        <Route 
          path="/case-study/:id" 
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <CaseStudy />
            </Suspense>
          }
        />
      </Routes>

      
    </>
  );
}

export default App;
