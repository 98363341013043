import React, { useRef, useEffect, useState } from "react";
import roco from "../../assets/images/roco.jpg";

import matias from "../../assets/images/legends/matias.jpg";
import chris from "../../assets/images/legends/chris.jpg";
import natan from "../../assets/images/legends/natan.jpg";

import heroImg from "../../assets/images/compressedHero.png";
import Dialog from "../Dialog/Dialog";

const Hero = ({ title, subtitle, callendlyLink, gmailLink }) => {
  
  /* ########### h1 ANIMATION ################### */
  const textRef = useRef(null); // Create a ref for the rotating text element

  useEffect(() => {
    const roles = ["MVP.", "website.", "SaaS.", "app.", "brand.", "UI/UX"];
    const waitingTime = 3000;

    let roleIndex = 0;

    function displayRoleText() {
      const textElement = textRef.current; // Access the element through the ref
      if (!textElement) return; // Safeguard in case element is not found

      textElement.innerHTML = ""; // Clear the current text
      const currentRole = roles[roleIndex];

      currentRole.split("").forEach((char, index) => {
        const charSpan = document.createElement("span");
        charSpan.classList.add("char");
        charSpan.textContent = char === " " ? "\u00A0" : char; // Preserve spaces
        charSpan.style.animationDelay = `${index * 0.05}s`; // Stagger each letter by 0.05s

        textElement.appendChild(charSpan);
      });

      roleIndex = (roleIndex + 1) % roles.length; // Cycle through roles
    }

    const intervalId = setInterval(displayRoleText, waitingTime);
    displayRoleText(); // Initial call

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  // ############# DIALOG #################

  // Use a ref to access the dialog element
  const dialogRef = useRef(null);

  // Function to show the dialog
  const showDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
  };

  // Function to close the dialog
  const closeDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
    }
  };

  // ######## POST TO GOOGLE ###########

  const [successMessage, setSuccessMessage] = useState(false);

  function postToGoogle() {
    const field1 = document.getElementById("name").value;
    const field2 = document.getElementById("email").value;
    const field3 = document.getElementById("message").value;

    fetch(
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSd3suydHl-vYPKFX_tNtYiVNM_ts4vmCipjNg2ab162E9SNqA/formResponse",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          "entry.1669622180": field1,
          "entry.713957402": field2,
          "entry.994485359": field3,
        }).toString(),
      }
    )
      .then(() => {
        document.getElementById("contact").reset();
        setSuccessMessage(true);
      })
      .catch(() => {
        document.getElementById("contact").reset();
        setSuccessMessage(true);
      });

    return false;
  }

  

  return (
    <>
      <div className="hero-container">
        <div className="hero">
          <div className="profile-hero-container">
            <img
              className="profile-img_hero"
              src={roco}
              alt="Roco Fernandez Pagella"
              loading="lazy"
            />

            <div className="profile-hero_text">
              <p>
                <b>Roco Fernandez Pagella</b>
              </p>
              <p>Designer / Developer</p>
            </div>
          </div>

          <div className="h1-container_hero">
            <h1>
              Let's build your next big <span ref={textRef}></span>
            </h1>
          </div>

          <p>{subtitle}</p>

          <div className="all-buttons-container_hero">
            <div className="buttons-container_hero">
              <a href={callendlyLink} target="_blank" rel="noopener noreferrer">
                <button className="book-call_hero animated">Book a short Call</button>
              </a>
              <a target="_blank" rel="noopener noreferrer">
                <Dialog buttonName={"Type your vision"} />
              </a>
            </div>
          </div>

        <div id="hero-legends">
          <img src={matias} alt="" />
          <img src={chris} alt="" />
          <img src={natan} alt="" />
          <p>Trusted by +19 Startup Founders (legends)</p>
        </div>

        </div>

        <div className="hero-right-img">
          <img src={heroImg} alt="" loading="lazy" />
        </div>

       
      </div>
    </>
  );
};

export default Hero;
