import coverOilyo from "./oilyo/oilyo.jpg";
import oilyoWork1 from "./oilyo/oilyoWork1.jpg";
import oilyoWork2 from "./oilyo/oilyoWork2.jpg";
import oilyoWork3 from "./oilyo/oilyoWork3.jpg";
import oilyoWork4 from "./oilyo/oilyoWork4.jpg";

import coverKiron from "./kiron/kiron.jpg";
import websiteKiron from "./kiron/website.png";

import coverInfire from "./infire/infire.jpg";
import infireWork1 from "./infire/infireWork1.jpg";

import coverVulcano from "./vulcano/vulcano.jpg";
import vulcanoWork1 from "./vulcano/vulcanoWork1.png";
import vulcanoWork2 from "./vulcano/vulcanoWork2.jpg";

import coverHighInvest from "./highinvest/highinvest.png";
import highinvestWork1 from "./highinvest/highinvestWork1.png";
import highinvestWork2 from "./highinvest/highinvestWork2.png";
import highinvestWork3 from "./highinvest/highinvestWork3.png";
import highinvestWork4 from "./highinvest/highinvestWork4.jpg";

import coverGreenSoap from "./greensoap/greensoap.jpg";
import greensoapWork1 from "./greensoap/greensoapWork1.jpg";
import greensoapWork2 from "./greensoap/greensoapWork2.jpg";
import greensoapWork3 from "./greensoap/greensoapWork3.jpg";
import greensoapWork4 from "./greensoap/greensoapWork4.png";
import greensoapWork5 from "./greensoap/greensoapWork5.png";
import greensoapWork6 from "./greensoap/greensoapWork6.jpg";
import greensoapWork7 from "./greensoap/greensoapWork7.jpg";

import coverGourmand from "./gourmand/gourmand.png";
import gourmandWork1 from "./gourmand/gourmandWork1.png";
import gourmandWork2 from "./gourmand/gourmandWork2.png";
import gourmandWork3 from "./gourmand/gourmandWork3.png";
import gourmandWork4 from "./gourmand/gourmandWork4.png";
import gourmandWork5 from "./gourmand/gourmandWork5.png";
import gourmandWork6 from "./gourmand/gourmandWork6.png";

import coverVoro from "./voro/voro.png";
import voroWeb from "./voro/voroWeb.png";

import coverTensor from "./tensor/tensor.png";
import tensorWork1 from "./tensor/tensorWork1.png";
import tensorWork2 from "./tensor/tensorWork2.jpg";
import tensorWork3 from "./tensor/tensorWork3.jpg";

import coverVeselaMotika from "./veselamotika/veselamotika.jpg";
import veselamotikaWork1 from "./veselamotika/veselamotikaWork1.png";

import coverEvolv from "./evolv/evolv.jpeg";
import evolvWork1 from "./evolv/evolvWork1.png";

import coverEvoka from "./evoka/evoka.png";
import evokaWork1 from "./evoka/evokaWork1.png";
import evokaWork2 from "./evoka/evokaWork2.png";
import evokaWork3 from "./evoka/evokaWork3.png";
import evokaWork4 from "./evoka/evokaWork4.png";

import coverBeeo from "./beeo/beeo.png";
import beeoWork1 from "./beeo/beeoWork1.png";
import beeoWork2 from "./beeo/beeoWork2.png";
import beeoWork3 from "./beeo/beeoWork3.png";
import beeoWork4 from "./beeo/beeoWork6.png";

import coverAyro from "./ayro/ayro.png";
import ayroWork1 from "./ayro/ayroWork1.jpg";

import coverAgenciaMeet from "./agenciameet/agenciameet.png";
import agenciaMeetWork1 from "./agenciameet/agenciaMeetWork1.png";
import agenciaMeetWork2 from "./agenciameet/agenciaMeetWork2.png";
import agenciaMeetWork3 from "./agenciameet/agenciaMeetWork3.png";

import coverHumaind from "./humaind/humaind.jpg";
import humaindWork1 from "./humaind/humaindWork1.jpg";
import humaindWork2 from "./humaind/humaindWork2.jpg";

import CaseStudy from "./CaseStudy";

const caseStudiesData = [
  {
    id: "CaseStudyOilyo",
    title: "Oilyo: Redefining Beauty",
    categories: ["Featured", "Branding"],
    cover: coverOilyo,
    backgroundColor: "#C7FC8E",
    //tags: ["Featured", "Branding"],

    content: () => (
      
        <CaseStudy
          title="Oilyo: Redefining Beauty"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book"]}
          what="Oilyo is a young and bold brand with a very simple assignment: to reach the market with simple, effective products and with very powerful images."
          why="Oilyo was created to simplify skincare in a market filled with overwhelming choices and complex products. The brand aims to empower people with straightforward, effective solutions that deliver real results without the fuss."
          how="Oilyo's branding is bold and minimalistic, featuring clean designs and clear messaging. Packaging is functional and user-friendly, emphasizing key ingredients. The brand leverages strong imagery, social media, and influencer partnerships to build a community focused on simplicity and effectiveness."
        >
          <div className="block">
            <img src={oilyoWork2} alt="Oilyo Work 2" />
            <img src={oilyoWork1} alt="Oilyo Work 1" />
            <img src={oilyoWork3} alt="Oilyo Work 3" />
            <img src={oilyoWork4} alt="Oilyo Work 4" />
          </div>
        </CaseStudy>
     
    ),
  },
  {
    id: "CaseStudyKiron",
    title: "Kiron: Project Managment App",
    categories: ["Featured"],
    cover: coverKiron,
    backgroundColor: "#BF311B",

    content: () => (
      
        <CaseStudy
          title="Kiron: Project Management App"
          role={["UI/UX Designer", "Website Designer", "Brand Designer"]}
          deliverables={["UI/UX App Design", "UI/UX Website Design", "Logo"]}
          what="Kiron is a project management web app designed to streamline workflows, enhance collaboration, and provide a comprehensive platform for teams to manage their projects efficiently."
          why="In today’s fast-paced work environment, teams often face challenges in managing multiple projects, deadlines, and collaboration among team members. Kiron was created to address these issues by offering an intuitive and user-friendly platform that simplifies project management. By integrating task assignments, progress tracking, and communication tools, Kiron empowers teams to focus on what matters most—delivering quality results on time."
          how="The design process for Kiron involved extensive user research and feedback sessions to ensure the app met the needs of its target audience. The UI/UX design emphasizes simplicity and clarity, with a clean layout that minimizes clutter and enhances usability. Key features include a customizable dashboard, drag-and-drop task management, and integrated chat functionalities to facilitate real-time communication among team members. The branding reflects a modern and professional aesthetic, instilling confidence in users."
        >
          <div className="block">
            <img src={websiteKiron} alt="Kiron App Screenshot" />
          </div>
        </CaseStudy>
     
    ),
  },
  {
    id: "CaseStudyInfire",
    title: "InFire: Branding & Web for Startups",
    categories: ["Featured","Branding"],
    cover: coverInfire,

    backgroundColor: "#BF311B",

    content: () => (
      
        <CaseStudy
          title="InFire: Branding & Web for Startups"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book"]}
          what="InFire is a dynamic branding and web design agency dedicated to empowering startups. The agency specializes in creating strong, memorable brand identities and cutting-edge websites that help emerging businesses make a lasting impact."
          why="InFire was founded to ignite the passion and potential of startups through powerful branding and web design. The agency's mission is to help emerging businesses stand out in a competitive market by creating strong, memorable brand identities."
          how="InFire's approach centers around capturing the essence of a startup's vision and translating it into impactful designs. The branding is dynamic and bold, reflecting the energy and ambition of the clients they serve. The name 'InFire' symbolizes the agency's commitment to fueling the inner drive of startups, helping them maintain their momentum and achieve their goals."
        >
          <div className="block">
            <img src={infireWork1} alt="Infire Project Showcase" />
          </div>
        </CaseStudy>
      
    ),
  },

  {
    id: "CaseStudyVulcano",
    title: "Vulcano: Mountain Expeditions",
    categories: ["Branding", "Website"],
    cover: coverVulcano,
    backgroundColor: "#380000", // Example color

    content: () => (
      
        <CaseStudy
          title="Vulcano Expediciones: Mountain Expeditions"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book"]}
          what=" Vulcano is an adventure tourism agency that organizes expeditions to
            the highest volcanoes in the Andes.
            The work with Vulcano involved creating their brand identity,
            designing their website in WordPress using Elementor, and developing
            an SEO content strategy."
          why=" During the COVID-19 pandemic, this tourism agency had to stop paying
            rent for their premises because the landlord wanted to keep charging
            the same amount, even though no one was working since we were all at
            home. Thus, Vulcano contacted me to create a digital strategy."
          how="Rebranding: The rebranding phase focused on redesigning Vulcano's visual
              identity to better reflect the brand’s essence and appeal to its
              target audience. This involved creating a cohesive and modern
              design language that was implemented across all brand materials,
              including the logo, color palette, typography, and marketing
              collateral. Optimization: The next step was to enhance the website's navigation for a better
              user experience and improved SEO performance. I optimized images,
              CSS files, and JavaScript to reduce loading times, ensuring the
              site was both visually appealing and technically sound. SEO strategy:    Next, I created a website structure that made sense for both the
              user and SEO. I added a section for “San Pedro de Atacama” (the
              location where the business operates) and a mountain blog. Within
              the “San Pedro de Atacama” section, I created subcategories for
              “Tours in San Pedro de Atacama,” “Volcanoes of San Pedro de
              Atacama,” “Hotels in Atacama,” and “Restaurants in Atacama” (all
              keywords we wanted to rank for). SEO Strategy results:    Currently, about 50% of Vulcano's customers come directly from the
              website. In fact, they didn’t rent a new premises because they saw
              that the flow of customers didn't slow down; instead, it only
              increased over time."
        >
          <div className="block">
            <figure>
              <img src={vulcanoWork1} alt="missing" />
              <figcaption>
                View of the last 16 months in Google Search Console, showing
                4.39M impressions and 163k clicks on the page (currently
                averaging 450 clicks per day).
              </figcaption>
            </figure>
          </div>

          <div className="block">
            <figure>
              <img src={vulcanoWork2} alt="missing" />
              <figcaption>Website Development</figcaption>
            </figure>
          </div>
        </CaseStudy>
      
    ),
  },

  {
    id: "CaseStudyHumaind",
    title: "HuMaIND: Cibersecurity Poster",
    categories: ["Featured", "Branding"],
    cover: coverHumaind,

    backgroundColor: "#13FFFF",
    content: () => (
      
        <CaseStudy
          title="HuMaIND: Cibersecurity Poster"
          role={["Graphic Designer"]}
          deliverables={["Poster"]}
          what=""
          why=" We would like you to represent a concept such as the coordination
            between the different components and actors of a cybersecurity
            solution, including both the 'good guys' and the 'bad guys'. Imagine
            a scenario as if it were a western or science fiction movie,
            incorporating elements like hackers, viruses, mobile devices, the
            cloud, artificial intelligence, blockchain, as well as
            investigators, police, citizens, and laws, or any others you would
            like to represent."
          how=" - In this representation, blue hues are used to symbolize 'the good
            guys'—security, identification, and trustworthiness. <br />-
            Conversely, red tones signify 'the bad guys'—malware, viruses, and
            hackers."
        >
          <div className="block">
            <img src={humaindWork1} alt="" />
          </div>

          <div className="block">
            <img src={humaindWork2} alt="" />
          </div>
        </CaseStudy>
      
    ),
  },

  {
    id: "CaseStudyHighInvest",
    title: "High Invest: Stock Analysis Tool",
    categories: ["Featured","Branding", "Website"],
    cover: coverHighInvest,
    backgroundColor: "#7633C3",

    content: () => (
      
        <CaseStudy
          title="High Invest: Stock Analysis Tool"
          role={["Brand Designer", "Web App Developer", "Web Designer"]}
          deliverables={["Functional Web App", "Landing Page", "Brand"]}
          what=" High Invest is an advanced platform for stock market analysis,
            designed to provide investors with critical information and sharp
            market insights to enhance their decision-making."
          why=" High Invest was born from my deep fascination with finance and
            investment. I wanted to combine my passion for the financial world
            with my entrepreneurial drive, creating a service that seamlessly
            integrates both."
          how=" I used JavaScript, HTML, and CSS along with Firebase for data
              management to build High Invest. My expertise in UI/UX design
              played a crucial role in creating a polished and intuitive user
              experience. The website, developed with WordPress and Elementor,
              is optimized for performance and offers smooth, accessible
              navigation, available at highinvest.io."
        >
          <div className="block">
            <img src={highinvestWork1} alt="missing" />
          </div>

          <figure>
            <div className="block">
              <img src={highinvestWork4} alt="missing" />
            </div>
            <figcaption>UI/UX Design on Figma</figcaption>
          </figure>

          <div className="two-column-metadata">
            <div className="block">
              <figure>
                <img src={highinvestWork2} alt="missing" />
                <figcaption>Landing Page highinvest.io</figcaption>
              </figure>
            </div>

            <div className="block">
              <figure>
                <img src={highinvestWork3} alt="missing" />
                <figcaption>Web App high-invest.web.app</figcaption>
              </figure>
            </div>
          </div>
        </CaseStudy>
     
    ),
  },
  {
    id: "CaseStudyGreenSoap",
    title: "Green Soap: Natural Beauty",
    categories: ["Branding", "Website"],
    cover: coverGreenSoap,
    backgroundColor: "#A68D3D",

    content: () => (
      
        <CaseStudy
          title="Green Soap: Natural Beauty"
          role={["Brand Designer", "Website Developer"]}
          deliverables={["Brand identity", "Website"]}
          what="Green Soap is a company dedicated to crafting 100% recycled soaps,
            combining environmental responsibility with high-quality personal
            care products."
          why="Green Soap was founded to address environmental concerns by offering
            eco-friendly, 100% recycled soaps. The company’s mission is to
            promote sustainability and reduce waste in the personal care
            industry while providing effective, high-quality products."
          how="For Green Soap, I developed a brand identity and web design using
              WordPress and Elementor. The branding emphasizes the company’s
              commitment to sustainability, with a clean, natural aesthetic. The
              website design showcases the eco-friendly ethos and product
              benefits, making it easy for customers to connect with Green
              Soap’s mission and values."
        >
          <div className="block">
            <img src={greensoapWork7} alt="missing" />
          </div>

          <div className="two-column-metadata">
            <div className="block">
              <img src={greensoapWork6} alt="missing" />
              <img src={greensoapWork1} alt="missing" />

              <div className="block">
                <img src={greensoapWork5} alt="missing" />
              </div>
            </div>

            <div className="block">
              <img src={greensoapWork3} alt="missing" />
              <img src={greensoapWork2} alt="missing" />

              <div className="block">
                <img src={greensoapWork4} alt="missing" />
              </div>
            </div>
          </div>
        </CaseStudy>
      
    ),
  },
  {
    id: "CaseStudyGourmand",
    title: "Gourmand: Premium Catering",
    categories: ["Branding"],
    cover: coverGourmand,
    backgroundColor: "#440004",

    content: () => (
      
        <CaseStudy
          title="Gourmand: Premium Catering"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book", "Collateral"]}
          what="Gourmand is a catering and banquet company specialized in premium
            events and weddings."
          why="Gourmand was created to elevate the dining experience at high-end
            events, offering exquisite cuisine and exceptional service. The
            company aims to make every occasion memorable by delivering culinary
            excellence and attention to detail."
          how="Gourmand focuses on creating bespoke menus tailored to the unique
              preferences of each client. The brand’s identity is refined and
              elegant, reflecting the luxury and sophistication of the events
              they cater. From presentation to taste, every aspect is
              meticulously crafted to ensure a premium experience that leaves a
              lasting impression."
        >
          <div className="block">
            <img src={gourmandWork1} alt="missing" />
          </div>

          <div className="two-column-metadata">
            <div className="block">
              <img src={gourmandWork2} alt="missing" />
            </div>

            <div className="block">
              <img src={gourmandWork3} alt="missing" />
            </div>
          </div>

          <div className="block">
            <img src={gourmandWork4} alt="missing" />
          </div>

          <div className="two-column-metadata">
            <div className="block">
              <img src={gourmandWork5} alt="missing" />
            </div>

            <div className="block">
              <img src={gourmandWork6} alt="missing" />
            </div>
          </div>
        </CaseStudy>
     
    ),
  },
  {
    id: "CaseStudyVoro",
    title: "Voro: Dog Food E-commerce",
    categories: ["Website"],
    cover: coverVoro,
    backgroundColor: "#1C4348",

    content: () => (
 
        <CaseStudy
          title={"Voro: Pet Food"}
          role={"Website Developer"}
          deliverables={"Website Development"}
          what={
            "Voro is an e-commerce platform for premium dog food, designed to provide pet owners with convenient access to high-quality products."
          }
          why={
            "The goal of Voro was to create a streamlined online presence that emphasizes product quality and fosters customer trust, standing out in a competitive pet food market."
          }
          how={
            "The website was developed in WordPress for easy content management and optimized for user experience, including clear navigation and an engaging layout to highlight the product offerings effectively."
          }
        >
        <div className="block">
          <p>
            Web designed by{" "}
            <a
              href="https://www.heydiseno.cl/en/graphic-design-chile/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hey Diseño
            </a>{" "}
            and developed in WordPress by me.
          </p>
        </div>

        <div className="block">
          <figure>
            <img src={voroWeb} alt="Website development for Voro" />
            <figcaption>Website Development</figcaption>
          </figure>
        </div>
        </CaseStudy>

    ),
  },
  {
    id: "CaseStudyTensor",
    title: "Tensor",
    categories: ["Branding"],
    cover: coverTensor,
    backgroundColor: "#E81840",

    content: () => (
      
        <CaseStudy
          title="Tensor"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand Design", "Brand Book"]}
          what="Tensor is a cutting-edge tech company specializing in AI and machine learning solutions that enhance business operations."
          why="The goal of Tensor is to provide innovative AI tools that streamline workflows, reduce operational costs, and increase productivity for organizations across various industries."
          how="We developed a brand identity that reflects Tensor's commitment to innovation and technology, with a sleek logo, modern color palette, and comprehensive brand guidelines. The marketing materials highlight the transformative potential of AI, using data-driven graphics and user-friendly interfaces."
        >
          <div className="block">
            <img src={tensorWork1} alt="Tensor Work 1" />
            <img src={tensorWork2} alt="Tensor Work 2" />
            <img src={tensorWork3} alt="Tensor Work 3" />
          </div>
        </CaseStudy>
    
    ),
  },
  {
    id: "CaseStudyVeselaMotika",
    title: "Vesela Motika: Live Green",
    categories: ["Branding", "Website"],
    cover: coverVeselaMotika,
    backgroundColor: "#027F64",

    content: () => (
      
        <CaseStudy
          title="Vesela Motika: Live Green"
          role={["Website Developer"]}
          deliverables={["Website Design & Development"]}
          what="Vesela Motika is focused on promoting sustainable practices and encouraging people to engage with the green movement."
        >
          <div className="block">
            <figure>
              <img src={veselamotikaWork1} alt="Website Vesela Motika" />
              <figcaption>Website Development</figcaption>
            </figure>
          </div>
        </CaseStudy>
    ),
  },
  {
    id: "CaseStudyEvolv",
    title: "Evolv: B2B Web Development",
    categories: ["Branding", "Website"],
    cover: coverEvolv,
    backgroundColor: "#024F8A",

    content: () => (
      
        <CaseStudy
          title="Evolv: B2B Web Development"
          role={["Web Developer", "UI/UX Designer"]}
          deliverables={["Website Design", "Development", "Branding"]}
          what="Evolv is a B2B web development agency focused on creating custom solutions for businesses looking to enhance their online presence."
          why="In today's digital age, businesses need a robust online presence to attract clients and convey their brand values effectively. Evolv aims to provide tailored web solutions that meet the unique needs of each client."
          how="The team at Evolv employs a client-centric approach, utilizing modern web technologies and design principles to create responsive, user-friendly websites that are optimized for performance and SEO."
        >
          <div className="block">
            <img src={evolvWork1} alt="Evolv Work 1" />
          </div>
        </CaseStudy>
    ),
  },

  {
    id: "CaseStudyEvoka",
    title: "Evoka: Local E-Commerce",
    categories: ["Featured", "Branding", "Website"],
    cover: coverEvoka,
    backgroundColor: "#FFC13A",

    content: () => (
      
        <CaseStudy
          title="Evoka: Local E-Commerce"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={[
            "Brand identity",
            "Brand Book",
            "Web Design & Development",
          ]}
          what="Evoka is an e-commerce platform dedicated to selling and transporting high-quality, culturally significant local products from San Pedro de Atacama. It aims to make your journey lighter and ensure your memories reach home."
          why="Evoka symbolizes movement, identity, and modernity, connecting travelers with authentic, local experiences while maintaining a contemporary and efficient service."
          how="The project involved developing Evoka’s brand identity and designing its e-commerce platform using WordPress and WooCommerce. The goal was to create a seamless and engaging online experience that reflects the cultural richness and quality of the products offered."
        >
          <div className="block">
            <img src={evokaWork1} alt="Evoka Work 1" />
          </div>

          <div className="two-column-metadata">
            <div className="block">
              <img src={evokaWork2} alt="Evoka Work 2" />
            </div>

            <div className="block">
              <img src={evokaWork3} alt="Evoka Work 3" />
            </div>
          </div>

          <div className="block">
            <img src={evokaWork4} alt="Evoka Work 4" />
          </div>
        </CaseStudy>
    ),
  },

  {
    id: "CaseStudyBeeo",
    title: "Beeo Cosmetics",
    categories: ["Branding"],
    cover: coverBeeo,
    backgroundColor: "#184651",

    content: () => (
      
        <CaseStudy
          title="Beeo Cosmética: Natural Cosmetics"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book", "Website Development"]}
          what="Beeo Cosmetics is a natural cosmetics company that uses beeswax to create eco-friendly products. The brand focuses on educating consumers about the benefits of beeswax and the importance of protecting bees, all while offering modern, sustainable cosmetics."
          why="Beeo aims to merge modern cosmetic needs with a commitment to environmental stewardship. The brand’s goal is to embody care, protection, and preservation of both bees and nature, providing products that are as beneficial for the environment as they are for the skin."
          how="I developed Beeo's brand identity and web design, creating a cohesive look that reflects its values. The website, built with WordPress and Elementor, showcases the brand's commitment to sustainability and education, offering an engaging user experience that aligns with Beeo's mission."
        >
          <div className="block">
            <figure>
              <img src={beeoWork2} alt="Logo inspiration and process" />
              <figcaption>Logo inspiration and process</figcaption>
            </figure>
          </div>

          <div className="block">
            <figure>
              <img src={beeoWork1} alt="Final Logo" />
              <figcaption>Final Logo</figcaption>
            </figure>
          </div>

          <div className="block">
            <figure>
              <img src={beeoWork3} alt="Alternative logo" />
              <figcaption>Alternative logo</figcaption>
            </figure>
          </div>

          <div className="block">
            <figure>
              <img src={beeoWork4} alt="Website Design" />
              <figcaption>Website Design</figcaption>
            </figure>
          </div>
        </CaseStudy>
    ),
  },

  {
    id: "CaseStudyAyro",
    title: "Ayro: Solar Energy",
    categories: ["Branding"],
    cover: coverAyro,
    backgroundColor: "#00F4F5",

    content: () => (
      
        <CaseStudy
          title="Ayro: Solar Energy"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book"]}
          what="Ayro is a pioneering company that uses advanced robotic technology to efficiently clean solar panels, ensuring optimal performance and longevity in the solar energy sector."
          why="Ayro was established to address the growing need for efficient and innovative solar panel cleaning solutions. As the solar energy sector expands, maintaining panel performance is crucial for maximizing energy output, and Ayro aims to provide a cutting-edge, automated solution to meet this need."
          how="I collaborated with the Ayro' co-founders to create a brand identity that conveys modernity, advanced technology, and professionalism. The new branding highlights Ayro’s innovative approach and enhances its visibility in the industry."
        >
          <div className="block">
            <img src={ayroWork1} alt="Ayro Work" />
          </div>
        </CaseStudy>
    ),
  },

  {
    id: "CaseStudyAgenciaMeet",
    title: "Agencia Meet: Events Agency",
    categories: ["Branding"],
    cover: coverAgenciaMeet,
    backgroundColor: "#9C72FF",

    content: () => (
      
        <CaseStudy
          title="Agencia Meet: Events Agency"
          role={["Brand Designer", "Brand Strategist"]}
          deliverables={["Brand identity", "Brand Book", "Brand Collateral"]}
          what="Meet is a dynamic event agency specializing in creating unforgettable experiences, from festive parties and birthdays to full-scale catering and themed celebrations. The agency excels in bringing unique visions to life, offering tailored event planning services that ensure every detail is perfectly executed."
          why="Meet was established to bring creativity and excitement to event planning, transforming ordinary occasions into memorable experiences. The agency aims to offer unique, personalized events that reflect the individuality and joy of their clients."
          how="I collaborated with Meet's founder to create a modern, vibrant brand identity that stands out in the event industry. The branding is designed to be visually striking, with bold colors and playful elements that capture the energy and personality of the events Meet organizes."
        >
          <div className="block">
            <img src={agenciaMeetWork1} alt="Agencia Meet Work 1" />
            <img src={agenciaMeetWork2} alt="Agencia Meet Work 2" />
            <img src={agenciaMeetWork3} alt="Agencia Meet Work 3" />
          </div>
        </CaseStudy>
    ),
  },
];

export default caseStudiesData;
